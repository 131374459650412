.home-padding{
  padding: 20px 10%;
}

h3{
  margin-left: 30px;
  font-weight: 600;
}

.list{
  float: left;
  // width: 330px;
  margin: 10px 10px;
  height: 400px;
  padding: 0px 20px;
  background: rgb(247, 247, 247);
  margin-right: 0px;
  border-right: 1px solid #e3e3e3;
  border-radius: 12px;

}

.domainList{
  display: flex;
  justify-content: space-between;
}

.card{
  justify-content: space-between;
  padding: 15px 25px;
  margin: 0px 10px;
  margin-bottom: 40px;
  border-radius: 12px;
  border:none;
  box-shadow: 10px 10px 10px rgb(211, 211, 211);
  height: 140px;
}

.dx-card{
  border:none;
  border-radius: 12px
}

.card1{
  @extend .card;
  border-right: 7px solid #FEC40E;
}
.card2{
  @extend .card;
  border-right: 7px solid #21A366;
}
.card3{
  @extend .card;
  border-right: 7px solid #EC3644;
}



.title{
  font-size: 18px;
  font-weight: 600;
  padding-bottom: 5px;
}

.description{
  padding-top: 10px;
  color: gray;
  font-size: 12px;
}

.subtitle{
  font-size: 14px;
  color: rgb(198, 198, 198);
  
}

.img{
  width: 72px;
  float: right;
}

.chart{
  background-color:rgb(255, 255, 255);
  padding: 15px 25px;
  margin: 0px 20px;
  border-radius: 12px;
}