#first-acordion { 
    .dx-accordion-item {
    // background-color: aqua;
    border: none;
    margin-bottom: 10px;
    background-color: rgb(238, 238, 238);
    border-radius: 4px;
}
    .dx-accordion {
    border-radius: 4px;
}
}

#second-acordion  {
    .toolbar-label {
    font-size: 14px;
    }
    .dx-accordion-item {
        background-color: transparent;
        // background-color: rgb(209, 209, 209);
        border-radius: 4px;
    }
}

.dx-accordion-item-body {
     padding: 8px 16px;
}

.left {
    float: left;
    // width: 330px;
    height: 470px;
    padding: 20px;
    background: rgba(191, 191, 191, 0.15);
    margin-right: 30px;
  }
  
